import { Grid, Paper, Typography } from "@material-ui/core";
import { isBoolean, isEmpty } from "lodash";
import React from "react";

export const PreferredSearchCriteria = ({ account }) => {
  const searchFilters = JSON.parse(
    account.userPreferences?.searchFilters ?? "{}"
  );

  const filterLabels = {
    accountCreatedBy: "Created By",
    civilStatuses: "Civil Statueses",
    differentlyAbled: "Differently Abled",
    drinking: "Drinking",
    educationLevels: "Education Levels",
    ethnicities: "Ethnicities",
    foodPreferences: "Food Preferences",
    gender: "Gender",
    idVerification: "ID Verification",
    maxAge: "Maximum Age",
    maxHeight: "Maximum Height",
    minAge: "Minimum Age",
    minHeight: "Minimum  Height",
    professions: "Professions",
    regionOrDistrict: "Region Or District",
    religions: "Religions",
    residentCountries: "Resident Countries",
    showInterests: "Show Interests",
    smoking: "Smoking",
    sortBy: "Sort By",
    heightRange: "Height Range",
  };

  let searchCriteria = [];

  for (const [key, value] of Object.entries(searchFilters)) {
    const label = filterLabels[key] ?? key;
    if (Array.isArray(value)) {
      const formattedValue = value.length ? value?.join(", ") : "-";
      searchCriteria.push({ label, value: formattedValue });
    } else if (isBoolean(value)) {
      searchCriteria.push({ label, value: value.toString() });
    } else if (typeof value === "object") {
      let output = "";
      for (const [nestedKey, nestedValue] of Object.entries(value)) {
        let val = `${nestedKey} ${nestedValue}`;
        output = `${(output += val)} `;
      }
      searchCriteria.push({ label, value: output });
    } else {
      const formattedValue = value ? value : "-";
      searchCriteria.push({ label, value: formattedValue });
    }
  }
  return (
    <Paper
      elevation={0}
      className="complete-ad-div settings-container post-details-con"
    >
      <Typography variant="h6" className="heading" style={{ marginBottom: 30 }}>
        Preferred Search Criteria
      </Typography>
      {!isEmpty(searchCriteria) ? (
        <Grid
          container
          className="preferred-search-grid"
          justifyContent="space-between"
        >
          {searchCriteria.map((element) => (
            <Grid item container className="search-criteria-container">
              <Typography variant="body2" className="search-criteria-label">
                {element.label}
              </Typography>
              <Typography
                variant="body2"
                style={{ width: "60%", padding: "4px 8px" }}
              >
                {element.value}
              </Typography>
            </Grid>
          ))}
        </Grid>
      ) : (
        <div
          style={{
            textAlign: "center",
            font: "inherit",
            fontSize: "15px",
            fontWeight: "400",
            lineHeight: "16px",
            color: "#333333",
          }}
        >
          <span>Preferred Search Criteria are not available</span>
        </div>
      )}
    </Paper>
  );
};
