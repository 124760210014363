// import packages
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import moment from "moment";
import { isEmpty, isEqual } from "lodash";

import { storeVirtualAdDetails } from "../../../actions/storeVirtualAdDetails";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Breadcrumb from "../../admin/breadcrumb/Breadcrumb";
import StepperVirtual from "../stepper/StepperVirtual";
import Personal from "../create-ad/sections/Personal";
import Parent from "../create-ad/sections/Parent";
import Private from "../create-ad/sections/Private";
import Payment from "../create-ad/sections/Payment";
import NextBtn from "../create-ad/sections/NextBtn";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import PostServiceApi from "../../../services/post-service";
import GenericApi from "../../../services/generics";
import { isAuthenticatedBoth } from "../../../auth/authBoth";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import ClearAll from "../create-ad/sections/ClearAll";

// import Preview from "./preview/Preview";

const initial = {
  paymentComplete: "",

  fname: "",
  lname: "",
  dob: "",
  gender: "",
  height: null,
  religionId: "",
  ethnicityId: "",
  caste: "",
  civilStatusId: "",
  children: null,
  residentCountryCode: "LK",
  residentCountryCodeOther: "",
  residentRegionId: null,
  residentCity: "",
  visaTypeId: "",
  educationLevelId: null,
  professionId: null,
  otherProfession: "",
  drinking: "",
  smoking: "",
  foodPreferenceId: "",
  additionalInfo: "",

  // father
  fOriginCountryCode: "",
  fEthnicityId: "",
  fReligionId: "",
  fProfessionId: "",
  fOtherProfession: "",
  fCaste: "",
  fAdditionalInfo: "",

  // mother
  mOriginCountryCode: "",
  mEthnicityId: "",
  mReligionId: "",
  mProfessionId: "",
  mOtherProfession: "",
  mCaste: "",
  mAdditionalInfo: "",

  // horoscope
  originCountryCode: "",
  horoscopeMatching: "",
  horoscopeDetail: "",
  birthCity: "",
  birthTime: "",

  // contact
  email: "",
  phone: "+94",

  // address
  showOfflineId: "",
  subscribeMagazine: "",
  recipName: "",
  line1: "",
  line2: "",
  city: "",

  accountCreatedById: "",

  paymentCompleteErr: "",
  fnameErr: "",
  lnameErr: "",
  dobErr: "",
  genderErr: "",
  heightErr: "",
  religionIdErr: "",
  ethnicityIdErr: "",
  civilStatusIdErr: "",
  childrenErr: "",
  residentCountryCodeErr: "",
  residentCountryCodeOtherErr: "",
  residentRegionIdErr: "",
  residentCityErr: "",
  visaTypeIdErr: "",
  educationLevelIdErr: "",
  professionIdErr: "",
  otherProfessionErr: "",
  drinkingErr: "",
  smokingErr: "",
  foodPreferenceIdErr: "",

  fOriginCountryCodeErr: "",
  fReligionIdErr: "",
  fEthnicityIdErr: "",
  fOtherProfessionErr: "",

  mOriginCountryCodeErr: "",
  mReligionIdErr: "",
  mEthnicityIdErr: "",
  mOtherProfessionErr: "",

  originCountryCodeErr: "",
  horoscopeMatchingErr: "",
  birthCityErr: "",
  birthTimeErr: "",

  emailErr: "",
  phoneErr: "",

  showOfflineIdErr: "",
  subscribeMagazineErr: "",
  recipNameErr: "",
  line1Err: "",
  cityErr: "",
  accountCreatedByIdErr: "",
  horoscopeDetailErr: "",
};

function CreateAdVirtualAgentCom(props) {
  const [filters, setFilters] = useState({
    religions: [],
    ethnicities: [],
    civilStatuses: [],
    countries: [],
    visaStatuses: [],
    educationLevels: [],
    professions: [],
    foodPreferences: [],
    regions: [],
    otherCountries: [],
  });
  const [form, setForm] = useState(initial);
  const [showOtherProf, setShowOtherProf] = useState(false);
  const [fShowOtherProf, setFShowOtherProf] = useState(false);
  const [mShowOtherProf, setMShowOtherProf] = useState(false);
  const [requiredAllFieldsErr, setRequiredAllFieldsErr] = useState(false);
  const [tempData, setTempData] = useState({});

  const location = useLocation();

  useEffect(() => {
    // filtes
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/generic/filters`)
      .then((res) => {
        const sortedReligions = res.data.religions.sort(
          (a, b) => a.sortOrder - b.sortOrder
        );

        const sortedEthnicities = res.data.ethnicities.sort(
          (a, b) => a.sortOrder - b.sortOrder
        );

        const sortedStatuses = res.data.civilStatuses.sort(
          (a, b) => a.sortOrder - b.sortOrder
        );

        const sortedCountries = res.data.countries.sort((a, b) =>
          a.name.localeCompare(b.name)
        );

        const sortedVisa = res.data.visaStatuses.sort(
          (a, b) => a.sortOrder - b.sortOrder
        );

        const sortedEdu = res.data.educationLevels.sort(
          (a, b) => a.sortOrder - b.sortOrder
        );

        const sortedPro = res.data.professions.sort((a, b) =>
          a.name.localeCompare(b.name)
        );

        let findOther = res.data.professions.filter(
          (filter) => filter.name === "Other"
        );

        const sortedFood = res.data.foodPreferences.sort(
          (a, b) => a.sortOrder - b.sortOrder
        );

        setFilters((filters) => ({
          ...filters,
          religions: sortedReligions,
          ethnicities: sortedEthnicities,
          civilStatuses: sortedStatuses,
          countries: sortedCountries,
          visaStatuses: sortedVisa,
          educationLevels: sortedEdu,
          professions: sortedPro,
          foodPreferences: sortedFood,
          idOfOther: findOther[0].id,
        }));
      })
      .catch((err) => console.log(err));

    if (
      props.data.post &&
      props.data.post.personalInfo.residentCountryCode === "ZZ"
    ) {
      // country
      axios
        .get(`${process.env.REACT_APP_API_URL}/v1/generic/countries`)
        .then((res) => {
          setFilters((filters) => ({
            ...filters,
            otherCountries: res.data,
            regions: [],
          }));
        })
        .catch((err) => console.log(err));
    } else {
      // regions
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/v1/generic/regions?countryCode=LK`
        )
        .then((res) => {
          const sortRegions = res.data.sort((a, b) =>
            a.regionName.localeCompare(b.regionName)
          );
          setFilters((filters) => ({
            ...filters,
            regions: sortRegions,
          }));
        })
        .catch((err) => console.log(err));
    }
  }, []);

  const getTempAccount = async () => {
    let tempFormData = {};
    const params = {
      memberId: props.memberId,
    };
    await PostServiceApi.getUserAccount({ params })
      .then((response) => {
        if (!response.success) {
          return;
        }
        const data = response.body ?? {};
        tempFormData = { ...data };
      })
      .catch(() => {
        console.log("Error");
      });

    await PostServiceApi.getUserTempPostData(props.memberId)
      .then((response) => {
        if (!response.success) {
          return;
        }
        const data = response.body?.postData ?? {};
        tempFormData = { ...tempFormData, ...data };
      })
      .catch((error) => {
        console.log("Error", error);
      });
    const tempData = tempFormData;
    setTempData((data) => ({ ...data, ...tempFormData }));
    getFormData(tempData);
  };

  useEffect(() => {
    if (!props.isEmptyPost && !props.memberId) {
      getFormData();
    }
    if (props.memberId !== undefined) {
      getTempAccount();
    }
  }, [props.isEmptyPost, props.memberId]);

  useEffect(() => {
    const countryCode = tempData?.personalInfo?.residentCountryCode;
    if (!isEmpty(countryCode)) {
      GenericApi.getCountryRegionsByCountryCode({ countryCode }).then(
        (response) => {
          if (!response.success) {
            return;
          }
          setFilters((filters) => ({
            ...filters,
            regions: response.body,
          }));
        }
      );
    }
  }, [tempData]);

  const getFormData = (tempData = {}) => {
    let formData = {};

    formData.fname =
      tempData?.personalInfo?.fname ??
      props.data?.post?.personalInfo?.fname ??
      "";

    formData.lname =
      tempData?.personalInfo?.lname ??
      props.data?.post?.personalInfo?.lname ??
      "";

    formData.dob =
      tempData?.personalInfo?.dob ?? props.data?.post?.personalInfo?.dob ?? "";
    formData.gender =
      tempData?.personalInfo?.gender ??
      props.data?.post?.personalInfo?.gender ??
      "";
    formData.ethnicityId =
      tempData?.personalInfo?.ethnicityId ??
      props.data?.post?.personalInfo?.ethnicityId ??
      "";
    formData.religionId =
      tempData?.personalInfo?.religionId ??
      props.data?.post?.personalInfo?.religionId ??
      "";
    formData.caste =
      tempData?.personalInfo?.caste ??
      props.data?.post?.personalInfo?.caste ??
      "";
    formData.civilStatusId =
      tempData?.personalInfo?.civilStatusId ??
      props.data?.post?.personalInfo?.civilStatusId ??
      "";
    formData.children =
      tempData?.personalInfo?.children ??
      props.data?.post?.personalInfo?.children ??
      "";
    formData.height =
      tempData?.personalInfo?.height ??
      props.data?.post?.personalInfo?.height ??
      "";
    formData.residentCountryCode =
      tempData?.personalInfo?.residentCountryCode ??
      props.data?.post?.personalInfo?.residentCountryCode ??
      "";

    formData.residentCountryCodeOther =
      tempData?.personalInfo?.residentCountryCodeOther ??
      props.data?.post?.personalInfo?.residentCountryCodeOther ??
      "";
    formData.residentRegionId =
      tempData?.personalInfo?.residentRegionId ??
      props.data?.post?.personalInfo?.residentRegionId ??
      "";
    formData.residentCity =
      tempData?.personalInfo?.residentCity ??
      props.data?.post?.personalInfo?.residentCity ??
      "";
    formData.visaTypeId =
      tempData?.personalInfo?.visaTypeId ??
      props.data?.post?.personalInfo?.visaTypeId ??
      "";
    formData.educationLevelId =
      tempData?.personalInfo?.educationLevelId ??
      props.data?.post?.personalInfo?.educationLevelId ??
      "";
    formData.professionId =
      tempData?.personalInfo?.professionId ??
      props.data?.post?.personalInfo?.professionId ??
      "";
    formData.otherProfession =
      tempData?.personalInfo?.otherProfession ??
      props.data?.post?.personalInfo?.otherProfession ??
      "";
    formData.drinking =
      tempData?.personalInfo?.drinking ??
      props.data?.post?.personalInfo?.drinking ??
      "";
    formData.smoking =
      tempData?.personalInfo?.smoking ??
      props.data?.post?.personalInfo?.smoking ??
      "";
    formData.foodPreferenceId =
      tempData?.personalInfo?.foodPreferenceId ??
      props.data?.post?.personalInfo?.foodPreferenceId ??
      "";
    formData.additionalInfo =
      tempData?.personalInfo?.additionalInfo ??
      props.data?.post?.personalInfo?.additionalInfo ??
      "";

    formData.fOriginCountryCode =
      tempData?.parentInfo?.[0]?.residentCountryCode ??
      props.data?.post?.parentInfo[0]?.originCountryCode ??
      "";
    formData.fEthnicityId =
      tempData?.parentInfo?.[0]?.ethnicityId ??
      props.data?.post?.parentInfo[0]?.ethnicityId ??
      "";
    formData.fReligionId =
      tempData?.parentInfo?.[0]?.religionId ??
      props.data?.post?.parentInfo[0]?.religionId ??
      "";
    formData.fCaste =
      tempData?.parentInfo?.[0]?.caste ??
      props.data?.post?.parentInfo[0].caste ??
      "";
    formData.fProfessionId =
      tempData?.parentInfo?.[0]?.professionId ??
      props.data?.post?.parentInfo[0]?.professionId ??
      "";
    formData.fOtherProfession =
      tempData?.parentInfo?.[0]?.otherProfession ??
      props.data?.post?.parentInfo[0]?.otherProfession ??
      "";
    formData.fAdditionalInfo =
      tempData?.parentInfo?.[0]?.additionalInfo ??
      props.data?.post?.parentInfo[0]?.additionalInfo ??
      "";
    formData.mOriginCountryCode =
      tempData?.parentInfo?.[1].residentCountryCode ??
      props.data?.post?.parentInfo[1]?.originCountryCode ??
      "";
    formData.mEthnicityId =
      tempData?.parentInfo?.[1]?.ethnicityId ??
      props.data?.post?.parentInfo[1]?.ethnicityId ??
      "";
    formData.mReligionId =
      tempData?.parentInfo?.[1]?.religionId ??
      props.data?.post?.parentInfo[1]?.religionId ??
      "";
    formData.mCaste =
      tempData?.parentInfo?.[1]?.caste ??
      props.data?.post?.parentInfo[1]?.caste ??
      "";
    formData.mProfessionId =
      tempData?.parentInfo?.[1]?.professionId ??
      props.data?.post?.parentInfo[1]?.professionId ??
      "";
    formData.mOtherProfession =
      tempData?.parentInfo?.[1]?.otherProfession ??
      props.data?.post?.parentInfo[1]?.otherProfession ??
      "";
    formData.mAdditionalInfo =
      tempData?.parentInfo?.[1]?.additionalInfo ??
      props.data?.post?.parentInfo[1]?.additionalInfo ??
      "";

    formData.originCountryCode =
      tempData?.personalInfo?.residentCountryCode ??
      props.data?.post?.personalInfo.originCountryCode ??
      "";
    formData.horoscopeMatching =
      tempData?.horoscopeMatching ?? props.data?.post?.horoscopeMatching
        ? "true"
        : "false";
    formData.birthTime =
      tempData?.birthTime ?? props.data?.post?.birthTime ?? "";
    formData.birthCity =
      tempData?.birthCity ?? props.data?.post?.birthCity ?? "";
    formData.horoscopeDetail =
      tempData?.horoscopeDetail ?? props.data?.horoscopeDetail ?? "";
    formData.email = tempData.email ?? props.data?.post?.email ?? "";

    formData.phone = tempData?.phoneNumber
      ? tempData?.phoneNumber?.replace("+", "")
      : props.data?.phoneNumber && props.data?.phoneNumber !== "+"
      ? props.data?.phoneNumber?.replace("+", "")
      : "+94";

    formData.paymentComplete =
      props.data?.paymentComplete !== ""
        ? props.data?.paymentComplete?.toString()
        : "";

    formData.showOfflineId =
      !!tempData.userPreferences?.showOfflineId ||
      !!props.data?.preferences?.showOfflineId
        ? "true"
        : "false";

    formData.subscribeMagazine =
      !!tempData.userPreferences?.subscribeMagazine ||
      !!props.data?.preferences?.subscribeMagazine
        ? "true"
        : "false";

    formData.recipName =
      tempData.address?.name ?? props.data?.userAddress?.name ?? "";

    formData.line1 =
      tempData.address?.line1 ?? props.data?.userAddress?.line1 ?? "";

    formData.line2 =
      tempData.address?.line2 ?? props.data?.userAddress?.line2 ?? "";

    formData.city =
      tempData.address?.city ?? props.data?.userAddress?.city ?? "";

    formData.accountCreatedById =
      tempData.accountOwner ??
      props.data?.post?.accountCreatedById ??
      props.data?.accountOwner ??
      "";

    setForm((form) => ({ ...form, ...formData }));

    setShowOtherProf(
      props.data?.post?.personalInfo?.professionId === 7 ? true : false
    );

    setFShowOtherProf(
      props.data?.post?.parentInfo[0]?.professionId === 7 ? true : false
    );

    setMShowOtherProf(
      props.data?.post?.parentInfo[1]?.professionId === 7 ? true : false
    );
  };

  useEffect(() => {
    saveToSession();
  }, [form]);

  const handleChange = (e) => {
    if (e.target.name === "professionId") {
      if (parseInt(e.target.value) === filters.idOfOther) {
        setShowOtherProf(true);
      } else {
        setShowOtherProf(false);
      }
    }

    if (e.target.name === "fProfessionId") {
      if (parseInt(e.target.value) === filters.idOfOther) {
        setFShowOtherProf(true);
      } else {
        setFShowOtherProf(false);
      }
    }

    if (e.target.name === "mProfessionId") {
      if (parseInt(e.target.value) === filters.idOfOther) {
        setMShowOtherProf(true);
      } else {
        setMShowOtherProf(false);
      }
    }

    if (
      e.target.name === "dob" &&
      new Date(moment(e.target.value).format("MM/DD/YYYY")) <
        new Date(moment().subtract(60, "years").calendar())
    ) {
      NotificationManager.warning(
        "The age of this member is above 60 years",
        "Warning !",
        3000
      );
    }

    setForm({
      ...form,
      [e.target.name]: e.target.value,
      [e.target.name + "Err"]: "",
    });
  };

  const handlePhone = (phone) => {
    setForm({
      ...form,
      phone: phone,
      phoneErr: "",
    });
  };

  const handleChangeCountry = (e) => {
    if (e.target.value === "ZZ") {
      setForm({
        ...form,
        residentRegionId: "",
        residentCountryCodeOther: "",
        residentCountryCode: e.target.value,
      });

      // country
      axios
        .get(`${process.env.REACT_APP_API_URL}/v1/generic/countries`)
        .then((res) => {
          setFilters((filters) => ({
            ...filters,
            otherCountries: res.data,
            regions: [],
          }));
        })
        .catch((err) => console.log(err));
    } else {
      setForm({
        ...form,
        residentRegionId: "",
        residentCountryCodeOther: "",
        residentCountryCode: e.target.value,
      });

      // regions
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/v1/generic/regions?countryCode=${e.target.value}`
        )
        .then((res) => {
          const sortRegions = res.data.sort((a, b) =>
            a.regionName.localeCompare(b.regionName)
          );
          setFilters((filters) => ({
            ...filters,
            regions: sortRegions,
          }));
        })
        .catch((err) => console.log(err));
    }
  };

  const saveToSession = () => {
    // create ad object
    const data = {
      paymentComplete: form.paymentComplete === "true" ? true : false,
      phoneNumber: `+${form.phone}`,
      post: {
        email: form.email ? form.email : null,
        makePhonePublic: false,
        horoscopeMatching: form.horoscopeMatching === "true" ? true : false,
        horoscopeDetail: form.horoscopeDetail ? form.horoscopeDetail : null,
        birthTime: form.birthTime ? form.birthTime : null,
        birthCity: form.birthCity ? form.birthCity : null,
        description: null,
        images: null,
        parentInfo: [
          {
            type: "FATHER",
            residentCountryCode: form.fOriginCountryCode,
            religionId: form.fReligionId,
            ethnicityId: form.fEthnicityId,
            professionId: parseInt(form.fProfessionId),
            otherProfession:
              parseInt(form.fProfessionId) === filters.idOfOther
                ? form.fOtherProfession
                  ? form.fOtherProfession
                  : null
                : null,
            caste: form.fCaste ? form.fCaste : null,
            additionalInfo: form.fAdditionalInfo ? form.fAdditionalInfo : null,
          },
          {
            type: "MOTHER",
            residentCountryCode: form.mOriginCountryCode,
            religionId: form.mReligionId,
            ethnicityId: form.mEthnicityId,
            professionId: parseInt(form.mProfessionId),
            otherProfession:
              parseInt(form.mProfessionId) === filters.idOfOther
                ? form.mOtherProfession
                  ? form.mOtherProfession
                  : null
                : null,
            caste: form.mCaste ? form.mCaste : null,
            additionalInfo: form.mAdditionalInfo ? form.mAdditionalInfo : null,
          },
        ],
        personalInfo: {
          fname: form.fname,
          lname: form.lname,
          dob: form.dob,
          gender: form.gender,
          height: form.height,
          religionId: form.religionId,
          ethnicityId: form.ethnicityId,
          originCountryCode: form.originCountryCode,
          residentCountryCode: form.residentCountryCode,
          residentCountryCodeOther: form.residentCountryCodeOther
            ? form.residentCountryCodeOther
            : null,
          residentRegionId: form.residentRegionId
            ? parseInt(form.residentRegionId)
            : null,
          residentCity: form.residentCity,
          visaTypeId: form.visaTypeId ? form.visaTypeId : "citizen",
          educationLevelId: parseInt(form.educationLevelId),
          professionId: parseInt(form.professionId),
          otherProfession:
            parseInt(form.professionId) === 7
              ? form.otherProfession
                ? form.otherProfession
                : null
              : null,
          civilStatusId: form.civilStatusId,
          children: form.children,
          drinking: form.drinking,
          smoking: form.smoking,
          foodPreferenceId: form.foodPreferenceId,
          caste: form.caste ? form.caste : null,
          additionalInfo: form.additionalInfo ? form.additionalInfo : null,
        },
      },

      userAddress:
        form.showOfflineId === "false" && form.subscribeMagazine === "false"
          ? null
          : {
              name: form.recipName.trim(),
              line1: form.line1.replace(/,\s*$/, "").trim(),
              line2: form.line2 ? form.line2.replace(/,\s*$/, "").trim() : "",
              city: form.city.replace(/,\s*$/, "").trim(),
              countryCode: "LK",
            },
      preferences: {
        showOfflineId: form.showOfflineId === "true" ? true : false,
        subscribeMagazine: form.subscribeMagazine === "true" ? true : false,
      },
    };

    props.storeVirtualAdDetails(data);

    sessionStorage.setItem("virtual_ad_data", JSON.stringify(data));
  };

  // validate
  const validate = () => {
    let paymentCompleteErr = "";
    let fnameErr = "";
    let lnameErr = "";
    let dobErr = "";
    let genderErr = "";
    let religionIdErr = "";
    let ethnicityIdErr = "";
    let civilStatusIdErr = "";
    let childrenErr = "";
    let heightErr = "";
    let residentCountryCodeErr = "";
    let residentCountryCodeOtherErr = "";
    let residentRegionIdErr = "";
    let residentCityErr = "";
    let visaTypeIdErr = "";
    let educationLevelIdErr = "";
    let professionIdErr = "";
    let otherProfessionErr = "";
    let drinkingErr = "";
    let smokingErr = "";
    let foodPreferenceIdErr = "";
    let fOriginCountryCodeErr = "";
    let mOriginCountryCodeErr = "";
    let fEthnicityIdErr = "";
    let mEthnicityIdErr = "";
    let fReligionIdErr = "";
    let mReligionIdErr = "";
    let fOtherProfessionErr = "";
    let mOtherProfessionErr = "";
    let originCountryCodeErr = "";
    let horoscopeMatchingErr = "";
    let birthCityErr = "";
    let birthTimeErr = "";
    let emailErr = "";
    let phoneErr = "";
    let showOfflineIdErr = "";
    let subscribeMagazineErr = "";
    let recipNameErr = "";
    let line1Err = "";
    let cityErr = "";
    let accountCreatedById = "";
    let accountCreatedByIdErr = "";

    if (form.paymentComplete === "") {
      paymentCompleteErr = "Payment status is required";
    }

    if (!form.fname) {
      fnameErr = "First name is required";
    } else if (form.fname.length < 2) {
      fnameErr = "First name is too short";
    } else if (!form.fname.match(/^\D+$/)) {
      fnameErr = "First name is Invalid";
    }

    if (!form.lname) {
      lnameErr = "Last name is required";
    } else if (form.lname.length < 2) {
      lnameErr = "First name is too short";
    } else if (!form.lname.match(/^\D+$/)) {
      lnameErr = "First name is Invalid";
    }

    if (!form.dob) {
      dobErr = "Birthdate is required";
    } else if (
      new Date(moment(form.dob).format("MM/DD/YYYY")) >
      new Date(moment().subtract(18, "years").calendar())
    ) {
      dobErr = "Age should be more than 18 years";
    }

    if (!form.gender) {
      genderErr = "Gender is required";
    }

    if (!form.religionId) {
      religionIdErr = "Religion is required";
    }

    if (!form.ethnicityId) {
      ethnicityIdErr = "Ethnicity is required";
    }

    if (!form.civilStatusId) {
      civilStatusIdErr = "Civil status is required";
    }

    if (form.civilStatusId !== "never_married" && !form.children) {
      childrenErr = "Children is required";
    }

    if (!form.height) {
      heightErr = "Height is required";
    }

    if (!form.residentCountryCode) {
      residentCountryCodeErr = "Resident country code is required";
    }

    if (!form.residentCity) {
      residentCityErr = "City is required";
    }

    if (form.residentCountryCode === "ZZ") {
      if (!form.residentCountryCodeOther) {
        residentCountryCodeOtherErr = "Country  is required";
      }
    } else {
      if (!form.residentRegionId && form.residentCountryCode !== "JP") {
        residentRegionIdErr = "State / District  is required";
      }
    }

    if (form.residentCountryCode !== "LK") {
      if (!form.visaTypeId) {
        visaTypeIdErr = "visa type is required";
      }
    }

    if (!form.educationLevelId) {
      educationLevelIdErr = "Education Level is required";
    }

    if (!form.professionId) {
      professionIdErr = "Profession is required";
    }

    if (form.professionId === filters.idOfOther) {
      if (parseInt(form.professionId) === filters.idOfOther) {
        if (!form.otherProfession) {
          otherProfessionErr = "Please type your profession";
        } else if (form.otherProfession.length < 2) {
          otherProfessionErr = "Invalid profession";
        }
      }
    }

    if (!form.drinking) {
      drinkingErr = "Drinking is required";
    }

    if (!form.smoking) {
      smokingErr = "Smoking is required";
    }

    if (!form.foodPreferenceId) {
      foodPreferenceIdErr = "Food preference is required";
    }

    if (!form.foodPreferenceId) {
      foodPreferenceIdErr = "Food preference is required";
    }

    if (!form.fOriginCountryCode) {
      fOriginCountryCodeErr = "Origin Country is required";
    }

    if (!form.mOriginCountryCode) {
      mOriginCountryCodeErr = "Origin Country is required";
    }

    if (!form.fEthnicityId) {
      fEthnicityIdErr = "Ethnicity is required";
    }

    if (!form.mEthnicityId) {
      mEthnicityIdErr = "Ethnicity is required";
    }

    if (!form.fReligionId) {
      fReligionIdErr = "Religion is required";
    }

    if (!form.mReligionId) {
      mReligionIdErr = "Religion is required";
    }

    if (
      form.fProfessionId &&
      parseInt(form.fProfessionId) === filters.idOfOther
    ) {
      if (!form.fOtherProfession) {
        fOtherProfessionErr = "Please type profession";
      } else if (form.fOtherProfession.length < 3) {
        fOtherProfessionErr = "Invalid profession";
      }
    }

    if (
      form.mProfessionId &&
      parseInt(form.mProfessionId) === filters.idOfOther
    ) {
      if (!form.mOtherProfession) {
        mOtherProfessionErr = "Please type profession";
      } else if (form.mOtherProfession.length < 3) {
        mOtherProfessionErr = "Invalid profession";
      }
    }

    if (!form.originCountryCode) {
      originCountryCodeErr = "Birth Country is required";
    }

    if (!form.horoscopeMatching) {
      horoscopeMatchingErr = "Horoscope matching is required";
    }

    // if (form.horoscopeMatching === "true") {
    //   if (!form.birthCity) {
    //     birthCityErr = "Birth city is required";
    //   }

    //   if (!form.birthTime) {
    //     birthTimeErr = "Birth time is required";
    //   }
    // }

    if (form.email) {
      if (!form.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
        emailErr = "Email is invalid";
      }
    }

    if (!form.phone) {
      phoneErr = "Phone is required";
    }

    if (!form.showOfflineId) {
      showOfflineIdErr = "This field is required";
    }

    if (!form.accountCreatedById) {
      accountCreatedByIdErr = "This field is required";
    }

    if (!form.subscribeMagazine) {
      subscribeMagazineErr = "This field is required";
    }

    if (form.showOfflineId === "true" || form.subscribeMagazine === "true") {
      if (!form.recipName) {
        recipNameErr = "Name of recipient is required";
      } else if (
        form.recipName.match(/[`0-9!@#$%^&*()_+\-=\[\]{};':"\\|<>\/?~]/)
      ) {
        recipNameErr = "Name of recipient is Invalid";
      } else if (!form.recipName.trim()) {
        recipNameErr = "Name of recipient is Invalid";
      }

      if (!form.line1) {
        line1Err = "Address line 1 is required";
      } else if (!form.line1.trim()) {
        line1Err = "Address line 1 is Invalid";
      }

      if (!form.city) {
        cityErr = "City is required";
      } else if (!form.city.trim()) {
        cityErr = "City is Invalid";
      }
    }

    if (
      paymentCompleteErr ||
      fnameErr ||
      lnameErr ||
      dobErr ||
      genderErr ||
      religionIdErr ||
      ethnicityIdErr ||
      civilStatusIdErr ||
      childrenErr ||
      heightErr ||
      residentCountryCodeErr ||
      residentCountryCodeOtherErr ||
      residentRegionIdErr ||
      residentCityErr ||
      visaTypeIdErr ||
      educationLevelIdErr ||
      professionIdErr ||
      otherProfessionErr ||
      drinkingErr ||
      smokingErr ||
      foodPreferenceIdErr ||
      fOriginCountryCodeErr ||
      mOriginCountryCodeErr ||
      fEthnicityIdErr ||
      mEthnicityIdErr ||
      fReligionIdErr ||
      mReligionIdErr ||
      fOtherProfessionErr ||
      mOtherProfessionErr ||
      originCountryCodeErr ||
      horoscopeMatchingErr ||
      birthCityErr ||
      birthTimeErr ||
      emailErr ||
      phoneErr ||
      showOfflineIdErr ||
      subscribeMagazineErr ||
      recipNameErr ||
      line1Err ||
      cityErr ||
      accountCreatedByIdErr
    ) {
      setForm({
        ...form,
        paymentCompleteErr,
        fnameErr,
        lnameErr,
        dobErr,
        genderErr,
        religionIdErr,
        ethnicityIdErr,
        civilStatusIdErr,
        childrenErr,
        heightErr,
        residentCountryCodeErr,
        residentCountryCodeOtherErr,
        residentRegionIdErr,
        residentCityErr,
        visaTypeIdErr,
        educationLevelIdErr,
        professionIdErr,
        otherProfessionErr,
        drinkingErr,
        smokingErr,
        foodPreferenceIdErr,
        fOriginCountryCodeErr,
        mOriginCountryCodeErr,
        fEthnicityIdErr,
        mEthnicityIdErr,
        fReligionIdErr,
        mReligionIdErr,
        fOtherProfessionErr,
        mOtherProfessionErr,
        originCountryCodeErr,
        horoscopeMatchingErr,
        birthCityErr,
        birthTimeErr,
        emailErr,
        phoneErr,
        showOfflineIdErr,
        subscribeMagazineErr,
        recipNameErr,
        line1Err,
        cityErr,
        accountCreatedByIdErr,
      });

      return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      if (!props.memberId) {
        const phoneData = qs.stringify({
          userId: `+${form.phone}`,
          idType: "PHONE",
        });
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/v1/auth/user_exists?${phoneData}`,
            {
              headers: {
                Authorization: `Bearer ${isAuthenticatedBoth()}`,
              },
            }
          )
          .then((res) => {
            if (res.status === 200) {
              setForm({
                ...form,
                phoneErr: "Phone number is used by another account",
              });
            } else {
              submitFormData();
            }
          })
          .catch((err) => console.log(err));
      } else {
        submitFormData();
      }
    } else {
      setRequiredAllFieldsErr(true);
    }
  };

  const submitFormData = () => {
    const data = {
      accountOwner: form.accountCreatedById,
      phoneNumber: `+${form.phone}`,
      paymentComplete: form.paymentComplete === "true" ? true : false,
      ...(props.memberId
        ? { memberId: props.memberId }
        : { phoneNumber: `+${form.phone}` }),
      post: {
        email: form.email ? form.email : null,
        makePhonePublic: false,
        horoscopeMatching: form.horoscopeMatching === "true" ? true : false,
        birthTime: form.birthTime ? form.birthTime : null,
        horoscopeDetail: form.horoscopeDetail ? form.horoscopeDetail : null,
        birthCity: form.birthCity ? form.birthCity : null,
        description: null,
        images: null,
        parentInfo: [
          {
            type: "FATHER",
            residentCountryCode: form.fOriginCountryCode,
            religionId: form.fReligionId,
            ethnicityId: form.fEthnicityId,
            professionId: parseInt(form.fProfessionId),
            otherProfession:
              parseInt(form.fProfessionId) === filters.idOfOther
                ? form.fOtherProfession
                  ? form.fOtherProfession
                  : null
                : null,
            caste: form.fCaste ? form.fCaste : null,
            additionalInfo: form.fAdditionalInfo ? form.fAdditionalInfo : null,
          },
          {
            type: "MOTHER",
            residentCountryCode: form.mOriginCountryCode,
            religionId: form.mReligionId,
            ethnicityId: form.mEthnicityId,
            professionId: parseInt(form.mProfessionId),
            otherProfession:
              parseInt(form.mProfessionId) === filters.idOfOther
                ? form.mOtherProfession
                  ? form.mOtherProfession
                  : null
                : null,
            caste: form.mCaste ? form.mCaste : null,
            additionalInfo: form.mAdditionalInfo ? form.mAdditionalInfo : null,
          },
        ],
        personalInfo: {
          fname: form.fname,
          lname: form.lname,
          dob: form.dob,
          gender: form.gender,
          height: form.height,
          religionId: form.religionId,
          ethnicityId: form.ethnicityId,
          originCountryCode: form.originCountryCode,
          residentCountryCode: form.residentCountryCode,
          residentCountryCodeOther: form.residentCountryCodeOther
            ? form.residentCountryCodeOther
            : null,
          residentRegionId: form.residentRegionId
            ? parseInt(form.residentRegionId)
            : null,
          residentCity: form.residentCity,
          visaTypeId: form.visaTypeId ? form.visaTypeId : "citizen",
          educationLevelId: parseInt(form.educationLevelId),
          professionId: parseInt(form.professionId),
          otherProfession:
            parseInt(form.professionId) === 7
              ? form.otherProfession
                ? form.otherProfession
                : null
              : null,
          civilStatusId: form.civilStatusId,
          drinking: form.drinking,
          smoking: form.smoking,
          foodPreferenceId: form.foodPreferenceId,
          caste: form.caste ? form.caste : null,
          additionalInfo: form.additionalInfo ? form.additionalInfo : null,
          children:
            form.civilStatusId !== "never_married" ? form.children : null,
        },
      },

      userAddress:
        form.showOfflineId === "false" && form.subscribeMagazine === "false"
          ? null
          : {
              name: form.recipName.trim(),
              line1: form.line1.replace(/,\s*$/, "").trim(),
              line2: form.line2 ? form.line2.replace(/,\s*$/, "").trim() : "",
              city: form.city.replace(/,\s*$/, "").trim(),
              countryCode: "LK",
            },
      preferences: {
        showOfflineId: form.showOfflineId === "true" ? true : false,
        subscribeMagazine: form.subscribeMagazine === "true" ? true : false,
      },
    };

    props.storeVirtualAdDetails(data);

    sessionStorage.setItem("virtual_ad_data", JSON.stringify(data));

    props.history.push("/dashboard/review-virtual-ad");
  };

  return (
    <div className="content-col">
      <Breadcrumb mainTitle="virtual Agent" title="Create Ad" />

      <div className="container-fluid inner-content">
        <StepperVirtual />

        {location.pathname === "/dashboard/virtual-ad-create" &&
          !isEqual(form, initial) && (
            <ClearAll setValues={setForm} initialValues={initial} />
          )}

        <Personal
          filters={filters}
          form={form}
          showOtherProf={showOtherProf}
          handleChange={handleChange}
          handleChangeCountry={handleChangeCountry}
        />

        <Parent
          filters={filters}
          form={form}
          fShowOtherProf={fShowOtherProf}
          mShowOtherProf={mShowOtherProf}
          handleChange={handleChange}
        />

        <Private
          filters={filters}
          form={form}
          phone={form.phone}
          handleChange={handleChange}
          handlePhone={handlePhone}
        />

        <Payment form={form} handleChange={handleChange} />

        <NextBtn
          requiredAllFieldsErr={requiredAllFieldsErr}
          handleSubmit={handleSubmit}
        />
      </div>
      <NotificationContainer />
    </div>
  );
}

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators({ storeVirtualAdDetails }, dispatch);
};

const mapStateToProps = (state) => {
  return {
    data: state.virtualAd.data,
    isEmptyPost:
      state.virtualAd.data.constructor === Object &&
      Object.keys(state.virtualAd.data).length === 0,
  };
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withRouter(CreateAdVirtualAgentCom));
