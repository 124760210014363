import React from "react";
import "../../../../sass/_common.scss";

const ClearAll = ({ setValues, initialValues }) => {
  return (
    <div className="virtual-agent-clear-all-div">
      <span className="clear-all-txt" onClick={() => setValues(initialValues)}>
        Clear All
      </span>
    </div>
  );
};

export default ClearAll;
