import React, { useState, useEffect } from "react";
import axios from "axios";
import { Paper, Grid, Typography } from "@material-ui/core";
import { PhotoProvider, PhotoConsumer } from "react-photo-view";
import _12FromTo24Hours from "12fromto24hours";

import { isAuthenticatedBoth } from "../../../../auth/authBoth";
import { isAdminOrOperatorL2 } from "../../../../auth/authAdminOrOperatorL2";
import DeleteHoroscopeImageModal from "../../modals/DeleteHoroscopeImageModal";
import Config from "../../../../config";

import { Delete } from "@material-ui/icons";
import DeleteBirthTimeModal from "../../modals/deleteBirthTimeModal";
import DataField from "../../data-field";

function Horoscope(props) {
  const [imageData, setImageData] = useState([]);
  const [deletingId, setDeletingId] = useState("");

  useEffect(() => {
    getHoroPhotos();
  }, [props.ad.horoscopeInfo && props.ad.horoscopeInfo.images]);

  const havingDoshValue = () => {
    let doshValue = "";
    switch (props.ad?.hasDosh) {
      case "YES":
        doshValue = "yes";
        break;
      case "NO":
        doshValue = "No";
        break;
      case "UNKNOWN":
        doshValue = "Don't know";
        break;
      default:
        doshValue = "-";
    }
    return doshValue;
  };

  const getHoroPhotos = () => {
    setImageData([]);
    if (
      props.ad.horoscopeInfo &&
      props.ad.horoscopeInfo.images &&
      props.ad.horoscopeInfo.images.length
    ) {
      for (let i = 0; i < props.ad.horoscopeInfo.images.length; i++) {
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/v1/account/posts/${props.ad.id}/horoscope_images/${props.ad.horoscopeInfo.images[i]}?width=500&height=500`,
            {
              headers: {
                Authorization: `Bearer ${isAuthenticatedBoth()}`,
              },
              responseType: "blob",
            }
          )
          .then((res) => {
            let data = URL.createObjectURL(res.data);
            let obj = { id: props.ad.horoscopeInfo.images[i], data: data };
            setImageData((imageData) => [...imageData, obj]);
          })
          .catch((err) => {
            console.log(err);
            return [];
          });
      }
    }
  };

  const handleDeleteImage = () => {
    props.delImages(deletingId);
  };

  return (
    <React.Fragment>
      <Paper
        elevation={0}
        className="complete-ad-div settings-container post-details-con"
      >
        <Typography
          variant="h6"
          className="heading"
          style={{ marginBottom: 30 }}
        >
          Horoscope Info
        </Typography>
        <Grid container direction="row" style={{ rowGap: 8 }}>
          <Grid container className="con-row" style={{ columnGap: 30 }}>
            <Grid item style={{ width: "48%", padding: 10 }}>
              <DataField
                fieldName={"BirthDay"}
                fieldValue={props.ad?.horoscopeInfo?.dob}
                fieldStyle={{ background: "#f2f2f2", cursor: "pointer" }}
                onClickShowModal={
                  isAdminOrOperatorL2()
                    ? (e) => {
                        props.openEditModal(
                          "Birth Day",
                          "dob",
                          "date",
                          props.ad?.horoscopeInfo?.dob || "",
                          [],
                          "personalInfo",
                          "SELF"
                        );
                      }
                    : null
                }
              />
              <DataField
                fieldName={"Birth City"}
                fieldValue={props.ad?.horoscopeInfo?.birthCity || "-"}
                fieldStyle={{ cursor: "pointer" }}
                onClickShowModal={
                  isAdminOrOperatorL2()
                    ? (e) => {
                        props.openEditModal(
                          "Birth City",
                          "birthCity",
                          "text",
                          props.horosDetails?.birthCity || "",
                          [],
                          null,
                          "SELF"
                        );
                      }
                    : null
                }
              />
              {Config.CURRENT_PLATFORM === Config.PLATFORMS.IN && (
                <React.Fragment>
                  <DataField
                    fieldName={"Star"}
                    fieldValue={props.ad?.personalInfo?.star?.name || "-"}
                    fieldStyle={{ background: "#f2f2f2", cursor: "pointer" }}
                    onClickShowModal={
                      isAdminOrOperatorL2()
                        ? (e) => {
                            props.openEditModal(
                              "Star",
                              "starId",
                              "select",
                              props.ad?.personalInfo?.star?.id || "",
                              props.starOptions,
                              "personalInfo",
                              "SELF"
                            );
                          }
                        : null
                    }
                  />
                  <DataField
                    fieldName={"Willing to marry from other Communities"}
                    fieldValue={props.ad?.acceptOtherCaste ? "Yes" : "No"}
                    fieldStyle={{ cursor: "pointer" }}
                    onClickShowModal={
                      isAdminOrOperatorL2()
                        ? (e) => {
                            props.openEditModal(
                              "Willing to marry from other communities",
                              "acceptOtherCaste",
                              "select",
                              props?.ad?.acceptOtherCaste ? true : false,
                              [
                                { type: "YES", id: true },
                                { type: "NO", id: false },
                              ],
                              null,
                              "SELF"
                            );
                          }
                        : null
                    }
                  />
                </React.Fragment>
              )}
              <DataField
                fieldName={"Additional Info"}
                fieldValue={props.ad?.horoscopeInfo?.additionalData || "-"}
                fieldStyle={{ background: "#f2f2f2", cursor: "pointer" }}
                onClickShowModal={
                  isAdminOrOperatorL2()
                    ? (e) => {
                        props.openEditModal(
                          "Additional Info",
                          "horoscopeDetail",
                          "text",
                          props.horosDetails?.additionalData || "",
                          [],
                          null,
                          "SELF"
                        );
                      }
                    : null
                }
              />
            </Grid>
            <Grid item style={{ width: "48%", padding: 10 }}>
              <DataField
                fieldName={"Matching"}
                fieldValue={
                  props.ad.horoscopeMatching ? "Essential" : "Not essential"
                }
                fieldStyle={{ background: "#f2f2f2", cursor: "pointer" }}
                onClickShowModal={
                  isAdminOrOperatorL2()
                    ? (e) => {
                        props.openEditModal(
                          "Horoscope Matching",
                          "horoscopeMatching",
                          "select",
                          props.ad?.horoscopeMatching,
                          props.horoscopeOptions,
                          null,
                          "SELF"
                        );
                      }
                    : null
                }
              />
              <DataField
                fieldName={"Birth Time"}
                fieldValue={
                  props?.ad?.horoscopeInfo?.birthTime
                    ? _12FromTo24Hours(props?.ad?.horoscopeInfo?.birthTime)
                    : "-"
                }
                fieldStyle={{ cursor: "pointer" }}
                onClickShowModal={
                  isAdminOrOperatorL2()
                    ? (e) => {
                        props.openEditModal(
                          "Birth Time",
                          "birthTime",
                          "time",
                          props.horosDetails?.birthTime || "",
                          [],
                          null,
                          "SELF"
                        );
                      }
                    : null
                }
                visibleIcon={props?.ad?.horoscopeInfo?.birthTime}
                Icon={() => (
                  <Delete
                    className="delete-icon"
                    fontSize="small"
                    data-toggle="modal"
                    data-target="#deleteBirthTimeModal"
                  />
                )}
              />
              {Config.CURRENT_PLATFORM === Config.PLATFORMS.IN && (
                <React.Fragment>
                  <DataField
                    fieldName={"Star sign"}
                    fieldValue={props.ad?.personalInfo?.starSign?.name || "-"}
                    fieldStyle={{ background: "#f2f2f2", cursor: "pointer" }}
                    onClickShowModal={
                      isAdminOrOperatorL2()
                        ? (e) => {
                            props.openEditModal(
                              "Star sign",
                              "starSignId",
                              "select",
                              props.ad?.personalInfo?.starSign?.id || "-",
                              props.starSignOptions,
                              "personalInfo",
                              "SELF"
                            );
                          }
                        : null
                    }
                  />
                  <DataField
                    fieldName={"Have dosh"}
                    fieldValue={` ${havingDoshValue()}`}
                    fieldStyle={{ cursor: "pointer" }}
                    onClickShowModal={
                      isAdminOrOperatorL2()
                        ? (e) => {
                            props.openEditModal(
                              "Having dosh",
                              "hasDosh",
                              "select",
                              props?.ad?.hasDosh || "-",
                              [
                                { id: "YES", type: "Yes" },
                                { id: "NO", type: "No" },
                                { id: "UNKNOWN", type: "Don't know" },
                              ],
                              null,
                              "SELF"
                            );
                          }
                        : null
                    }
                  />
                </React.Fragment>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid container style={{ padding: 10 }}>
          {props.ad.horoscopeInfo && props.ad.horoscopeInfo.images ? (
            <PhotoProvider>
              {imageData &&
                imageData.map((item, index) => (
                  <div>
                    <PhotoConsumer
                      key={index}
                      src={item.data}
                      intro={item.data}
                    >
                      <img
                        src={item.data}
                        alt="horescope images"
                        className="photos"
                      />
                    </PhotoConsumer>
                    <div style={{ textAlign: "center", color: "#dc3545" }}>
                      {isAdminOrOperatorL2() && (
                        <i
                          onClick={() => setDeletingId(item.id)}
                          data-toggle="modal"
                          data-target="#deleteHoroscopeImageModal"
                          class="fas fa-trash delete-img-icon"
                          style={{ cursor: "pointer" }}
                        ></i>
                      )}
                    </div>
                  </div>
                ))}
              {isAdminOrOperatorL2() && (
                <input
                  type="file"
                  id="horoscopeImgUpload"
                  className="imgUpload"
                  name="photo"
                  onChange={props.handleUpload}
                  accept="image/x-png,image/jpeg,image/jpg"
                />
              )}
              {isAdminOrOperatorL2() &&
                props.ad.horoscopeInfo.images.length <
                  Config.HOROSCOPE_IMAGE_SLOTS && (
                  <label
                    className="img-upload-label"
                    htmlFor="horoscopeImgUpload"
                    style={{
                      margin: "25px 15px 0 0",
                      height: "120px",
                      width: "120px",
                    }}
                  >
                    {props.imageUploading ? (
                      <span className="spinner-border spinner-border-sm"></span>
                    ) : (
                      <i class="fas fa-plus add-img-icon"></i>
                    )}
                  </label>
                )}
            </PhotoProvider>
          ) : null}
        </Grid>

        <DeleteHoroscopeImageModal handleDeleteImage={handleDeleteImage} />
      </Paper>
      <DeleteBirthTimeModal
        title={"Are You sure ?"}
        description={"Do you want to delete the birth time?"}
        handleDelete={props.handleDelete}
      />
    </React.Fragment>
  );
}

export default Horoscope;
